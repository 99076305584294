export default new class {

    constructor() {
        this.defaultSettings = {
            defaultEditLanguage: '',
            rowsPerTable: 20,
        };

        this.settings = {};

        const storageSettings = localStorage.getItem('settings');

        if(storageSettings === null) {
            this.setDefaultSettings();
        } else {
            try {
                const parsedSettings = JSON.parse(storageSettings);

                for(let [key, value] of Object.entries(this.defaultSettings)) {
                    if(typeof parsedSettings[key] === 'undefined') {
                        this.settings[key] = value;
                    } else {
                        this.settings[key] = parsedSettings[key];
                    }
                }

                this.save();
            } catch(error) {
                this.setDefaultSettings();
            }
        }
    }

    save() {
        localStorage.setItem('settings', JSON.stringify(this.settings));
    }

    setDefaultSettings() {
        this.settings = {...this.defaultSettings};

        this.save();
    }

    getSetting(key) {
        return this.settings[key];
    }

    setSetting(key, value) {
        this.settings[key] = value;

        this.save();
    }

    setSettings(settings) {
        for(let key of Object.keys(this.settings)) {
            if(typeof settings[key] === 'undefined') continue;

            this.settings[key] = settings[key];
        }

        this.save();
    }
}