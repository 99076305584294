import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
Vue.use(Router)

export default new Router({
    linkExactActiveClass: 'active',
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: 'dashboard',
            component: DashboardLayout,
            children: [
                {
                    path: '/dashboard',
                    name: 'dashboard',
                    // route level code-splitting
                    // this generates a separate chunk (about.[hash].js) for this route
                    // which is lazy-loaded when the route is visited.
                    component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue'),
                    meta: {
                        title: 'Dashboard',
                    },
                },
                {
                    path: '/hotels',
                    name: 'hotels',
                    component: () => import(/* webpackChunkName: "demo" */ './views/Hotels.vue'),
                    props: true,
                    meta: {
                        title: 'Hotels',
                    },
                },
                {
                    path: '/hotel',
                    name: 'hotel',
                    component: () => import(/* webpackChunkName: "demo" */ './views/Edit/EditHotel.vue'),
                    props: true,
                    meta: {
                        title: 'Hotel',
                    },
                },
                {
                    path: '/bookings',
                    name: 'bookings',
                    component: () => import(/* webpackChunkName: "demo" */ './views/Bookings.vue'),
                    props: true,
                    meta: {
                        title: 'Buchungen',
                    },
                },
                {
                    path: '/booking',
                    name: 'booking',
                    component: () => import(/* webpackChunkName: "demo" */ './views/Edit/EditBooking.vue'),
                    props: true,
                    meta: {
                        title: 'Buchung',
                    },
                },
                {
                    path: '/users',
                    name: 'users',
                    component: () => import(/* webpackChunkName: "demo" */ './views/Users.vue'),
                    props: true,
                    meta: {
                        title: 'Benutzer',
                    },
                },
                {
                    path: '/user',
                    name: 'user',
                    component: () => import(/* webpackChunkName: "demo" */ './views/Edit/EditUser.vue'),
                    props: true,
                    meta: {
                        title: 'Benutzer',
                    },
                },
                {
                    path: '/statistics',
                    name: 'statistics',
                    component: () => import(/* webpackChunkName: "demo" */ './views/Statistics.vue'),
                    props: true,
                    meta: {
                        title: 'Statistiken',
                    },
                },
                {
                    path: '/hotel-statistics',
                    name: 'hotel-statistics',
                    component: () => import(/* webpackChunkName: "demo" */ './views/HotelStatistics.vue'),
                    props: true,
                    meta: {
                        title: 'Hotel-Statistiken',
                    },
                },
                {
                    path: '/languages-and-countries',
                    name: 'languages-and-countries',
                    component: () => import(/* webpackChunkName: "demo" */ './views/LanguagesAndCountries.vue'),
                    meta: {
                        title: 'Sprachen & Länder',
                    },
                },
                {
                    path: '/email-templates',
                    name: 'email-templates',
                    props: true,
                    component: () => import(/* webpackChunkName: "demo" */ './views/Email-templates.vue'),
                    meta: {
                        title: 'Email-templates',
                    },
                },
                {
                    path: '/edit-email-template',
                    name: 'edit-email-template',
                    component: () => import(/* webpackChunkName: "demo" */ './views/Edit/EditEmailTemplate.vue'),
                    props: true,
                    meta: {
                        title: 'Email-templates',
                    },
                },
                {
                    path: '/email-template-preview',
                    name: 'email-preview',
                    component: () => import(/* webpackChunkName: "demo" */ './views/Email-templates-preview.vue'),
                    props: true,
                    meta: {
                        title: 'Email-templates-preview',
                    },
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue'),
                    meta: {
                        title: 'Mein Profil',
                    },
                },
                {
                    path: '/settings',
                    name: 'settings',
                    component: () => import(/* webpackChunkName: "demo" */ './views/Settings.vue'),
                    meta: {
                        title: 'Einstellungen',
                    },
                },
                {
                    path: '/prices',
                    name: 'prices',
                    component: () => import(/* webpackChunkName: "demo" */ './views/Prices.vue'),
                    props: true,
                    meta: {
                        title: 'Preise',
                    },
                },
                {
                    path: '/price',
                    name: 'price',
                    component: () => import(/* webpackChunkName: "demo" */ './views/Edit/EditPrice.vue'),
                    props: true,
                    meta: {
                        title: 'Preis',
                    },
                },
                {
                    path: '/show-hotel-edits',
                    name: 'show-hotel-edits',
                    component: () => import(/* webpackChunkName: "demo" */ './views/Edit/ShowHotelEdits.vue'),
                    props: true,
                    meta: {
                        title: 'Preis',
                    },
                },
            ]
        },
        {
            path: '/',
            redirect: 'login',
            component: AuthLayout,
            children: [
                {
                    path: '/login',
                    name: 'login',
                    component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue'),
                    meta: {
                        title: 'Login',
                    },
                },
                {
                    path: '/forgot-password',
                    name: 'forgot-password',
                    component: () => import(/* webpackChunkName: "demo" */ './views/ForgotPassword.vue'),
                    meta: {
                        title: 'Passwort vergessen',
                    },
                }
            ]
        }
    ]
})
