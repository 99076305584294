<template>
  <base-nav class="navbar-top navbar-dark"
          id="navbar-main"
          :show-toggle-button="false"
          expand>
    <ul class="navbar-nav align-items-center d-none d-md-flex ml-lg-auto">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0" position="right">
          <div class="media align-items-center" slot="title">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Profilbild" src="img/theme/profile-icon.png">
            </span>

            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold">{{ user.name }}</span>
            </div>
          </div>

          <template>
            <div class=" dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>

            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>Mein Profil</span>
            </router-link>

            <router-link to="/settings" class="dropdown-item">
              <i class="ni ni-settings-gear-65"></i>
              <span>Einstellungen</span>
            </router-link>

            <div class="dropdown-divider"></div>

            <a href="#" class="dropdown-item" @click="logoutUser">
              <i class="ni ni-button-power"></i>
              <span>Abmelden</span>
            </a>
          </template>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import apiService from '@/apiService'

export default {
  data() {
    return {
      showMenu: false,
      user: apiService.getUser(),
    };
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logoutUser() {
      apiService.logout();
    }
  },
  created() {
    apiService.on('user-change', user => {
      this.user = user;
    });
  }
};
</script>
