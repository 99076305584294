/*!

=========================================================
* Vue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import Vuelidate from 'vuelidate'
import apiService from './apiService'
import eventBus from './eventBus'
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

Vue.config.productionTip = false

const defaultTitle = document.title;

router.beforeEach(async (to, from, next) => {
  if(to.meta.title) {
    document.title = `${defaultTitle} | ${to.meta.title}`;
  } else {
    document.title = defaultTitle;
  }

  const loggedIn = await apiService.isLoggedIn();
  const hasTOTP = await apiService.hasTOTP();

  if(to.name !== 'login' && !loggedIn) {
    next({name: 'login'});
  }
  else if(loggedIn && !hasTOTP && to.name !== 'profile') {
    next({name: 'profile'});

    app.$notify({
      type: 'warning',
      title: 'Bitte aktivieren Sie die 2-Faktor-Authentifizierung!'
    });
  }
  else {
    if(eventBus.get('forceClose') === true) {
      eventBus.set('forceClose', false);
      eventBus.set('unsaveToClose', false);

      next();
    } else {
      eventBus.emit('prepare-to-close');

      if(eventBus.get('unsaveToClose') === true) {
        eventBus.emit('unsave-to-close', to);
      } else {
        next();
      }
    }
  }
})

apiService.on('logout', () => {
  router.push('login')
})

Vue.use(ArgonDashboard)
Vue.use(Vuelidate)
Vue.use(VueQuillEditor)

const app = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

apiService.on('notification', (type, title) => {
  app.$notify({type, title})
})