<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      short-title="Personights"
      title="Personights"
    >
      <template slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'ni ni-tv-2 text-dark', path: '/dashboard'}"/>
        <sidebar-item :link="{name: 'Hotels', icon: 'ni ni-building text-blue', path: '/hotels'}"/>
        <sidebar-item :link="{name: 'Preise', icon: 'ni ni-money-coins text-purple', path: '/prices'}"/>
        <sidebar-item :link="{name: 'Buchungen', icon: 'ni ni-tag text-orange', path: '/bookings'}"/>
        <sidebar-item :link="{name: 'Benutzer', icon: 'ni ni-single-02 text-green', path: '/users'}"/>
        <sidebar-item :link="{name: 'Statistiken', icon: 'ni ni-chart-pie-35 text-red', path: '/statistics'}"/>
        <sidebar-item :link="{name: 'Sprachen & Länder', icon: 'ni ni-world-2 text-info', path: '/languages-and-countries'}"/>
        <sidebar-item :link="{name: 'Email-Templates', icon: 'ni ni-email-83 text-info', path: '/email-templates'}"/>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in" @before-enter="scrollToTop">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>

    <modal :show.sync="closedWithoutSaved"
            gradient="primary"
            modal-classes="modal-danger modal-dialog-centered">
      <div class="py-3 text-center">
        <i class="fas fa-save fa-3x"></i>
        <h4 class="heading mt-4">Eintrag vor dem Schließen speichern?</h4>
        <p class="mb-0">Der Eintrag hat noch ungespeicherte Änderungen.</p>
      </div>

      <template slot="footer">
        <base-button type="white" @click="saveAndClose">
          <i v-if="spinners.closeModalButton" class="fas fa-circle-notch fa-spin"></i>
          Speichern &amp; Schließen
        </base-button>
        <base-button type="link"
                      text-color="white"
                      class="ml-auto"
                      @click="forceClose">
          Nicht speichern
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';
  import eventBus from '@/eventBus';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        closedWithoutSaved: false,
        closeParams: null,
        spinners: {
          closeModalButton: false,
        },
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      scrollToTop() {
        window.scrollTo(0, 0);
      },
      saveAndClose() {
        eventBus.emit('save-and-close');
        
        this.spinners.closeModalButton = true;
      },
      forceClose() {
        eventBus.set('unsaveToClose', false);
        eventBus.set('forceClose', true);
        
        this.$router.push(this.closeParams);

        this.spinners.closeModalButton = false;
        this.closedWithoutSaved = false;
        this.closeParams = null;
      },
    },
    created() {
      eventBus.on('unsave-to-close', closeParams => {
        this.closedWithoutSaved = true;
        this.closeParams = closeParams;
      });

      eventBus.on('force-close', () => {
        this.forceClose();
      });

      eventBus.on('entry-saved', success => {
        if(success) this.forceClose();

        this.spinners.closeModalButton = false;
        this.closedWithoutSaved = false;
      });
    },
  };
</script>
<style lang="scss">
</style>
