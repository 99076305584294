export default new class {
    
    constructor() {
        this.uid = 0;
        this.events = {};
        this.singleEvents = {};
        this.values = {};
    }

    set(name, value) {
        this.values[name] = value;
    }

    get(name) {
        return this.values[name];
    }

    on(event, callback) {
        if(typeof this.events[event] === 'undefined') {
            this.events[event] = [];
        }

        const id = this.uid++;

        this.events[event] = [
            ...this.events[event],
            { callback, id}
        ];

        return id;
    }

    once(event, callback) {
        if(typeof this.singleEvents[event] === 'undefined') {
            this.singleEvents[event] = [];
        }

        const id = this.uid++;

        this.singleEvents[event] = [
            ...this.singleEvents[event],
            { callback, id}
        ];

        return id;
    }

    off(event, id) {
        if(typeof this.events[event] !== 'undefined') {
            this.events[event] = this.events[event].filter(function(listener) {
                return listener.id !== id;
            });
        }

        if(typeof this.singleEvents[event] !== 'undefined') {
            this.singleEvents[event] = this.singleEvents[event].filter(function(listener) {
                return listener.id !== id;
            });
        }
    }

    emit(event, data = null) {
        if(typeof this.events[event] !== 'undefined') {   
            this.events[event].forEach(function(listener) {
                listener.callback(data);
            });
        }

        if(typeof this.singleEvents[event] !== 'undefined') {   
            this.singleEvents[event] = this.singleEvents[event].filter(function(listener) {
                listener.callback(data);

                return false;
            });
        }
    }
}