export default class Localized {

    constructor(entry) {
        this.translationStatus = {
            translated: true,
            fieldsToTranslate: [],
            needsTranslation: key => {
                if(typeof this.translationStatus.fieldsToTranslate === 'undefined') return false;

                return this.translationStatus.fieldsToTranslate.includes(key);
            },
        };

        for(let [key, value] of Object.entries(entry)) {
            if(typeof value === 'string' && /^TRANSLATE: /.test(value)) {
                this.translationStatus.translated = false;
                this.translationStatus.fieldsToTranslate = [
                    ...this.translationStatus.fieldsToTranslate,
                    key,
                ];

                value = value.replace(/^TRANSLATE: /, '');
            }

            this[key] = value;
        }
    }
}